// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbar-patient-list {
  padding-right: 0;
}

.sc-ion-searchbar-md-h {
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/patient-list/patient-list.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".searchbar-patient-list {\n    padding-right: 0;\n}\n\n.sc-ion-searchbar-md-h {\n    padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
