// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_component {
  overflow: auto;
  width: 100%;
  white-space: nowrap;
}

.table_component table {
  border-collapse: collapse;
}

.table_component th {
  border-bottom: 2px solid #d3d3d3;
  padding: 5px;
  text-align: left;
}

.table_component td {
  border-bottom: 1px solid #d3d3d3;
  padding: 5px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./../../libs/csv-patient-import/csv-patient-import-protocol/csv-patient-import-protocol.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,gCAAA;EACA,YAAA;EACA,gBAAA;AACJ","sourcesContent":[".table_component {\n    overflow: auto;\n    width: 100%;\n    white-space: nowrap;\n}\n\n.table_component table {\n    border-collapse: collapse;\n}\n\n.table_component th {\n    border-bottom: 2px solid #d3d3d3;\n    padding: 5px;\n    text-align: left;\n}\n\n.table_component td {\n    border-bottom: 1px solid #d3d3d3;\n    padding: 5px;\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
