import { Component, Input, OnInit } from '@angular/core';
import { DateFormat, ItemType, TableConfig } from '../../../../table/entities/table';
import { FrontendTherapySession } from '../../../entities/therapy-session/therapy-session';
import { SortBy } from '../../../../common/entities/paginated-response';
import { ExerciseSessionState, FrontendExerciseSessionOfUser } from '../../../entities/exerciseSession';
import { RangeSelectAdapterComponent } from '../../table-adapter/range-selector-item-adapter.component';
import { CommentFeedbackAdapterComponent } from '../../table-adapter/comment-feedback-adapter.component';
import { BorgPainFeedbackAdapterComponent } from '../../table-adapter/borg-pain-feedback-adapter.component';
import { FeedbackAdapterComponent } from '../../table-adapter/feedback-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';
import { TableModule } from '../../../../table/table.module';
import { CurafidaTableComponent } from '../../../../table/components/curafida-table/curafida-table.component';

@Component({
    selector: 'lib-training-result-table',
    templateUrl: './training-result-table.component.html',
    styleUrls: ['./training-result-table.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule, TableModule, CurafidaTableComponent],
})
export class TrainingResultTableComponent implements OnInit {
    exerciseSessionsOfUserTable: TableConfig<FrontendExerciseSessionOfUser[]> = new TableConfig<
        FrontendExerciseSessionOfUser[]
    >();
    isLoading = true;
    isLoadingSuccess = false;

    @Input()
    therapySession: FrontendTherapySession;
    isModal = false;

    private async getTherapySession() {
        this.exerciseSessionsOfUserTable.list.items = this.therapySession
            .exerciseSessionsOfUser as FrontendExerciseSessionOfUser[];
        this.exerciseSessionsOfUserTable.list.items.sort((a, b) => {
            return FrontendExerciseSessionOfUser.compareFeedbackExerciseSessions(a, b);
        });
        for (const frontendExerciseSessionOfUser of this.exerciseSessionsOfUserTable.list.items) {
            frontendExerciseSessionOfUser.exerciseName = frontendExerciseSessionOfUser.exerciseSession.title;
            frontendExerciseSessionOfUser.comment = frontendExerciseSessionOfUser.feedback?.feedbackComment;
            if (frontendExerciseSessionOfUser.exerciseSession.exerciseSessionState === ExerciseSessionState.PLANNED) {
                frontendExerciseSessionOfUser.exerciseSessionUpdateTime = null;
            } else {
                frontendExerciseSessionOfUser.exerciseSessionUpdateTime = frontendExerciseSessionOfUser.exerciseSession
                    .updated_at
                    ? frontendExerciseSessionOfUser.exerciseSession.updated_at
                    : null;
            }
        }
        this.exerciseSessionsOfUserTable.list.total = this.exerciseSessionsOfUserTable.list.items.length;
        this.exerciseSessionsOfUserTable.list.limit = this.exerciseSessionsOfUserTable.list.items.length;
        this.exerciseSessionsOfUserTable.list.count = this.exerciseSessionsOfUserTable.list.items.length;
        this.exerciseSessionsOfUserTable.list.offset = 0;
        this.isLoadingSuccess = true;
    }

    initUserTherapySessionTable() {
        this.exerciseSessionsOfUserTable.emptyListLabel = 'ANY_TRAINING_TEMPLATE';
        this.exerciseSessionsOfUserTable.itemSettings = [
            {
                id: 'exerciseName',
                prop: 'exerciseName',
                header: 'Übung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                sortBy: SortBy.TITLE,
                columnPosition: 0,
            },
            {
                id: 'exerciseSessionUpdateTime',
                prop: 'exerciseSessionUpdateTime',
                header: 'Datum',

                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '15%',
                columnPosition: 0,
            },
            {
                id: 'exerciseGoal',
                prop: 'exerciseGoal',
                header: 'SOLL Werte',
                type: ItemType.ADAPTER,
                adapter: RangeSelectAdapterComponent,
                width: '25%',
                columnPosition: 0,
                label: 'true',
                isOnlyToShow: true,
            },
            {
                id: '',
                prop: '',
                header: 'IST Werte',
                type: ItemType.ADAPTER,
                adapter: FeedbackAdapterComponent,
                width: '16%',
                columnPosition: 0,
                label: 'true',
            },
            {
                id: '',
                prop: 'feedback',
                header: 'Feedback',
                type: ItemType.ADAPTER,
                adapter: BorgPainFeedbackAdapterComponent,
                width: '15%',
                columnPosition: 0,
            },
            {
                id: '',
                prop: 'comment',
                header: 'Kommentar',
                type: ItemType.ADAPTER,
                adapter: CommentFeedbackAdapterComponent,
                width: '14%',
                columnPosition: 0,
            },
        ];
    }

    async ngOnInit() {
        this.initUserTherapySessionTable();
        await this.getTherapySession();
    }
}
