import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { IonicColor } from '../common/entities/toast/ionic-color';
import { LoadingService } from '../common/services/loading/loading.service';
import { ToastService } from '../common/services/toast-service/toast-service.service';
import { Logger, LoggingService } from '../logging/logging.service';
import { CsvImportProtocol } from './csv-patient-import.model';

@Injectable({
    providedIn: 'root',
})
export class CsvPatientImportService {
    protected readonly log: Logger;

    constructor(
        private readonly http: HttpClient,
        private readonly loggingService: LoggingService,
        private readonly toastService: ToastService,
        private readonly loadingService: LoadingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    public async selectCsvFile(
        hiddenFileUploadInputField: ElementRef,
        uploader: FileUploader,
    ): Promise<CsvImportProtocol> {
        const self = this;
        return new Promise((resolve, reject) => {
            uploader.clearQueue();
            hiddenFileUploadInputField.nativeElement.click();
            uploader.onAfterAddingAll = async (fileItems: FileItem[]) => {
                if (!fileItems || fileItems.length !== 1) {
                    this.log.error('Invalid csv file picker selection.');
                    return;
                }
                const fileItem = fileItems[0];
                self.loadingService.startLoadingModal('Import der Daten wurde gestartet');
                hiddenFileUploadInputField.nativeElement.value = '';
                uploader.clearQueue();
                const csvImportProtocol$ = this.uploadCsvFile(fileItem);
                csvImportProtocol$.subscribe({
                    next(value) {
                        self.toastService.showToast(
                            'Datei entspricht den Vorgaben. Import der Daten wurde gestartet',
                            IonicColor.success,
                        );
                        self.loadingService.stopLoadingModal();
                        resolve(value);
                    },
                    error(err) {
                        self.toastService.showToast(
                            'Datei entspricht nicht den Vorgaben. Bitte passen Sie die Datei an und versuchen Sie es erneut.',
                            IonicColor.danger,
                        );
                        self.loadingService.stopLoadingModal();
                        reject(err);
                    },
                });
            };
            uploader.onWhenAddingFileFailed = async (item: FileLikeObject, filter: any, options: any) => {
                switch (filter.name) {
                    // The size filter first has to be enabled in the FileUploader options
                    case 'fileSize':
                        await this.toastService.showToast(
                            `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                            IonicColor.danger,
                        );
                        break;
                    case 'mimeType':
                        await this.toastService.showToast(
                            `Die ausgewählte Datei ${item.name} entspricht nicht dem richtigen Format.`,
                            IonicColor.danger,
                        );
                        break;
                    // The queue limit first has to be enabled in the FileUploader options
                    case 'queueLimit':
                        await this.toastService.showToast(
                            `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                            IonicColor.danger,
                        );
                        break;
                    default:
                        this.log.error('Error in selectFile', `Unknown error (filter is ${filter.name})`);
                        await this.toastService.showToast(
                            `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                            IonicColor.danger,
                        );
                        break;
                }
                return reject();
            };
        });
    }

    private uploadCsvFile(file: FileItem): Observable<CsvImportProtocol> {
        const url = ApiService.url + 'csvPatientImport/importPatients';
        const formdataBody = new FormData();
        formdataBody.append('file', file._file);
        return this.http.post<CsvImportProtocol>(url, formdataBody);
    }
}
