import { Component, Input } from '@angular/core';
import { CommonComponentsModule } from '../../common/components/common-components.module';
import { CsvImportProtocol, CsvPatientCreateStatus } from '../csv-patient-import.model';

@Component({
    standalone: true,
    selector: 'csv-patient-import-protocol',
    imports: [CommonComponentsModule],
    templateUrl: './csv-patient-import-protocol.component.html',
    styleUrls: ['./csv-patient-import-protocol.component.scss'],
})
export class CsvPatientImportProtocolComponent {
    public title = 'CSV_PATIENT_IMPORT.CSV_IMPORT';

    @Input()
    public csvImportProtocol: CsvImportProtocol;

    public constructTableValue(rawValue: string, status: CsvPatientCreateStatus) {
        if (status === CsvPatientCreateStatus.SUCCESSFUL_ADDED && rawValue !== null) {
            // Wert erfolgreich importiert
            return `✅ ${rawValue}`;
        } else if (rawValue !== null) {
            // Wert vorhanden
            return `❌ ${rawValue}`;
        }
        // Wert nicht vorhanden
        return '❌';
    }
}
