import { IonicColor } from './toast/ionic-color';

export class ButtonDefinition {
    iconName?: string;
    iconSrc?: string;
    label?: string;
    color?: IonicColor;
    actionName?: string;

    constructor(actionName: string, iconName?: string, iconSrc?: string, label?: string, color?: IonicColor) {
        this.iconName = iconName;
        this.iconSrc = iconSrc;
        this.label = label;
        this.color = color;
        this.actionName = actionName;
    }
}
