import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UserTagsService } from '../../services/user-tags/user-tags.service';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../common/services/modal';
import { Logger, LoggingService } from '../../../logging/logging.service';
import {
    UpdateUserTagCategoryDto,
    UserTagCategoryDto,
    UserTagCategoryView,
} from '../../entities/user-tag/user-tag-category.entity';
import { TagModalComponent } from '../../../therapy/components/modal/tag-modal/tag-modal.component';
import { UpdateUserTagDto, UserTag, UserTagDto } from '../../entities/user-tag/user-tag.entity';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { UserRoles } from '../../../auth/entities/user';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { CurafidaSegmentItem } from '../../../common/entities/curafida-segment.item';
import { ConfigService } from '../../../config/services';

@Component({
    selector: 'lib-user-tags',
    templateUrl: './user-tag.component.html',
    styleUrls: ['./user-tag.component.scss'],
})
export class UserTagComponent implements OnInit {
    tagCategoryViews: PaginatedResponse<UserTagCategoryView[]>;
    selectedTagCategory: UserTagCategoryView;
    segmentUserPage: CurafidaSegmentItem<UserRoles>[] = [];
    segmentType: UserRoles;
    isLoadingFinished = false;
    protected readonly log: Logger;

    constructor(
        private modalAlertService: ModalAlertService,
        private toastService: ToastService,
        private userTagsService: UserTagsService,
        private modalController: ModalController,
        private translate: TranslateService,
        private loggingService: LoggingService,
        private configService: ConfigService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        this.initSegmentGroup();
        this.segmentType = this.segmentUserPage[0].value;
        await this.getTagList(this.segmentType);
        this.selectedTagCategory = this.tagCategoryViews.items[0];
    }

    initSegmentGroup() {
        this.segmentUserPage.push(new CurafidaSegmentItem({ name: 'USER.PATIENT.PLURAL', value: UserRoles.PATIENT }));
        this.segmentUserPage.push(
            new CurafidaSegmentItem({ name: 'USER.CAREGIVER.PLURAL', value: UserRoles.CAREGIVER }),
        );

        if (this.configService.config.features.user.supervisor?.enabled) {
            this.segmentUserPage.push(
                new CurafidaSegmentItem({ name: 'USER.SUPERVISOR.PLURAL', value: UserRoles.SUPERVISOR }),
            );
        }
        if (this.configService.config.features.user.user_manager?.enabled) {
            this.segmentUserPage.push(
                new CurafidaSegmentItem({ name: 'USER.USER_MANAGER.2_WORDS', value: UserRoles.USER_MANAGER }),
            );
        }
        if (this.configService.config.features.user.catalog_manager?.enabled) {
            this.segmentUserPage.push(
                new CurafidaSegmentItem({ name: 'USER.CATALOG_MANAGER.2_WORDS', value: UserRoles.CATALOG_MANAGER }),
            );
        }
        if (this.configService.config.features.user.analyst?.enabled) {
            this.segmentUserPage.push(
                new CurafidaSegmentItem({ name: 'USER.ANALYST.SINGULAR', value: UserRoles.ANALYST }),
            );
        }
    }

    async changeSegment(event: UserRoles) {
        this.segmentType = event;
        this.tagCategoryViews = new PaginatedResponse<UserTagCategoryView[]>();
        this.selectedTagCategory = new UserTagCategoryView();
        await this.getTagList(this.segmentType);
        this.selectedTagCategory = this.tagCategoryViews.items[0];
    }

    async addNewTagCategory() {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CATEGORY.CREATE',
                tag: new UserTag(),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                const userTagCategoryDto = new UserTagCategoryDto(data.tag.label, this.segmentType);
                await this.userTagsService.createUserTagCategories(userTagCategoryDto);
                await this.toastService.showToast('TAG.CATEGORY.SUCCESS.CREATE', IonicColor.success);
            } catch (err) {
                this.log.error('Error in addNewTagCategorie', err);
                if (err.error.message === 'This tag category already exists.') {
                    await this.toastService.showToast('TAG.CATEGORY.ERROR.ALREADY_EXISTS', IonicColor.danger);
                } else if (err.error.message === 'label must be longer than or equal to 1 characters') {
                    await this.toastService.showToast('TAG.CATEGORY.ERROR.TOO_SHORT', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            } finally {
                await this.getTagList(this.segmentType);
                this.selectedTagCategory = this.tagCategoryViews.items[0];
            }
        }
    }

    async getTagList(userRole: UserRoles) {
        this.isLoadingFinished = false;
        this.tagCategoryViews = new PaginatedResponse<UserTagCategoryView[]>();
        try {
            this.tagCategoryViews = (await this.userTagsService.getUserTagCategories({
                userRole: userRole,
            })) as PaginatedResponse<UserTagCategoryView[]>;
            this.tagCategoryViews.items = this.tagCategoryViews.items.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
            for (const tag of this.tagCategoryViews.items) {
                tag.areSubLinesHidden = true;
                tag.tags.sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });
            }
        } catch (e) {
            this.log.error(e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        } finally {
            this.isLoadingFinished = true;
        }
    }

    async addNewTag() {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CREATE',
                tag: new UserTag(),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                const userTagDto = new UserTagDto();
                userTagDto.label = data.tag.label;
                userTagDto.categoryUuid = this.selectedTagCategory.uuid;
                await this.userTagsService.createUserTag(userTagDto);
                const categoryUuid = this.selectedTagCategory.uuid;
                this.tagCategoryViews = new PaginatedResponse<UserTagCategoryView[]>();
                this.selectedTagCategory = new UserTagCategoryView();
                await this.getTagList(this.segmentType);
                this.selectedTagCategory = this.tagCategoryViews.items.find((i) => i.uuid === categoryUuid);
                await this.toastService.showToast(
                    this.translate.instant('TAG.SUCCESS.CREATE', { label: userTagDto.label }),
                    IonicColor.success,
                );
            } catch (err) {
                this.log.error('Error in addNewTag', err);
                if (err.error.message === 'This tag already exists.') {
                    await this.toastService.showToast('TAG.ERROR.ALREADY_EXISTS', IonicColor.danger);
                } else if (err.error.message === 'label must be longer than or equal to 1 characters') {
                    await this.toastService.showToast('TAG.ERROR.TOO_SHORT', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
            }
        }
    }

    async deleteTag(tag: UserTag, tagCategory: UserTagCategoryView) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = this.translate.instant('DELETE_TAG', { label: tag.label });
        modalConfig.description = this.translate.instant('TAG_IS_DELETE', { label: tag.label });
        modalConfig.titleIcon = 'warning-outline';

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.userTagsService.deleteUserTag(tag.uuid);
                tagCategory.tags.splice(
                    tagCategory.tags.findIndex((item) => item.uuid === tag.uuid),
                    1,
                );
                this.toastService.showToast('TAG_WAS_DELETED', IonicColor.success);
            } catch (err) {
                this.log.error('Error in deleteTag', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async updateTag(tag: UserTag) {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.UPDATE',
                tag,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                const userTagDto: UpdateUserTagDto = { uuid: data.tag.uuid, label: data.tag.label };
                await this.userTagsService.updateUserTag(userTagDto);
            } catch (err) {
                this.log.error('Error in updateTag', err);
                if (err.error.message === 'This tag already exists.') {
                    await this.toastService.showToast('TAG.ERROR.ALREADY_EXISTS', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList(this.segmentType);
            }
        }
    }

    async updateCategoryTag(tagCategory: UserTagCategoryView) {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CATEGORY.UPDATE',
                tag: tagCategory,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                const updateUserTagCategoryDto: UpdateUserTagCategoryDto = {
                    uuid: data.tag.uuid,
                    label: data.tag.label,
                };
                await this.userTagsService.updateUserTagCategories(updateUserTagCategoryDto);
            } catch (err) {
                this.log.error('Error in updateCategoryTag', err);
                if (err.error.message === 'This tag category already exists.') {
                    await this.toastService.showToast('TAG.CATEGORY.ERROR.ALREADY_EXISTS', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList(this.segmentType);
                this.selectedTagCategory = this.tagCategoryViews.items[0];
            }
        }
    }

    async deleteTagCategory(tagCategory: UserTagCategoryView) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.title = this.translate.instant('DELETE_TAG', { label: tagCategory.label });
        modalConfig.description = this.translate.instant('TAG.CATEGORY.PROMPT.DELETE', { label: tagCategory.label });

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                for (const tag of tagCategory.tags) {
                    await this.userTagsService.deleteUserTag(tag.uuid);
                }
                await this.userTagsService.deleteTagCategories(tagCategory.uuid);
                this.tagCategoryViews.items.splice(
                    this.tagCategoryViews.items.findIndex((item) => item.uuid === tagCategory.uuid),
                    1,
                );
                this.toastService.showToast('TAG.CATEGORY.SUCCESS.DELETE', IonicColor.success);
            } catch (err) {
                this.log.error('Error in deleteTagCategory', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                await this.getTagList(this.segmentType);
                this.selectedTagCategory = this.tagCategoryViews.items[0];
            }
        }
    }

    segmentChanged(userTagCategoryView: UserTagCategoryView) {
        this.selectedTagCategory = new UserTagCategoryView();
        this.selectedTagCategory = userTagCategoryView;
    }

    setDeleteButton(tag: UserTagCategoryView, action: string) {
        if (action === 'out') {
            tag.isDeleteIconDisabled = true;
            tag.iconName = '';
        }
        if (action === 'over') {
            tag.isDeleteIconDisabled = false;
            tag.iconName = 'trash-outline';
        }
    }
}
