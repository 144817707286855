import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { CurafidaCardComponent } from '../../../common/components/curafida-card/curafida-card.component';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { User, UserRoles } from '../../../auth/entities/user';
import { UserTagsService } from '../../services/user-tags/user-tags.service';
import { UserTag, UserTagAssignmentDto } from '../../entities/user-tag/user-tag.entity';
import { UserTagListModalComponent } from '../user-tag-list-modal/user-tag-list-modal.component';
import { ICardActionButton } from '../../../common/entities/card-action-button.interface';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'lib-user-tag-view',
    templateUrl: './user-tag-view.component.html',
    styleUrls: ['./user-tag-view.component.scss'],
})
export class UserTagViewComponent implements OnInit {
    @ViewChild('curafidaCardComponent') curafidaCardComponent: CurafidaCardComponent;
    @Input()
    user: User;
    title = 'Tags';
    isEditEnabled = false;
    @Input()
    isMobile = false;
    UserRoles = UserRoles;
    @Output()
    toggleEditEmit: EventEmitter<boolean> = new EventEmitter<boolean>();
    actionButtons: ICardActionButton[];
    @Input()
    isNewElement: boolean;
    @Input()
    formGroup: FormGroup;
    @Input()
    saveLabel = 'SAVE';
    @Output()
    cancelEditEmit: EventEmitter<any> = new EventEmitter();
    @Output()
    saveInformationEmit: EventEmitter<any> = new EventEmitter();
    @Input()
    canEdit: boolean;
    protected readonly log: Logger;

    constructor(
        private toastService: ToastService,
        private userTagsService: UserTagsService,
        private modalController: ModalController,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    private _isButtonHidden = false;

    get isButtonHidden(): boolean {
        return this._isButtonHidden;
    }

    @Input()
    set isButtonHidden(value: boolean) {
        this._isButtonHidden = value;
        if (this.actionButtons) {
            this.curafidaCardComponent.actionButtons = this.actionButtons;
        }
        if (value) {
            this.isEditEnabled = !value;
        }
        this.initActionButton();
    }

    initActionButton() {
        this.actionButtons = [
            {
                icon: 'add',
                id: 'add-care',
                isDisabled: false,
                isHidden: this.isButtonHidden,
                isIconButton: this.isMobile,
                title: 'TAG.ADD_NEW',
            },
        ];
    }

    ngOnInit(): void {
        this.initActionButton();
    }

    async addNewTags() {
        const modal = await this.modalController.create({
            component: UserTagListModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                title: `${this.user.firstname} ${this.user.lastname}`,
                userRoles: this.user.roles,
                tags: this.user.tags,
                dismissText: 'Speichern',
                clearSelectedTagText: 'Verwerfen',
                user: this.user,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            try {
                const difference = this.user.tags.filter((x) => !data.includes(x));
                this.user.tags = data;

                const removeTagAssignmentDto = new UserTagAssignmentDto(difference.map((i) => i.uuid));
                if (removeTagAssignmentDto.tagUuids.length > 0) {
                    if (this.user.roles.includes(UserRoles.PATIENT)) {
                        await this.userTagsService.removeUserTagFromPatient(this.user.username, removeTagAssignmentDto);
                    } else {
                        await this.userTagsService.removeUserTagFromUser(this.user.username, removeTagAssignmentDto);
                    }
                }

                const userTagAssignmentDto = new UserTagAssignmentDto(this.user.tags.map((i) => i.uuid));
                if (userTagAssignmentDto.tagUuids.length > 0) {
                    if (this.user.roles.includes(UserRoles.PATIENT)) {
                        await this.userTagsService.assignUserTagToPatient(this.user.username, userTagAssignmentDto);
                    } else {
                        await this.userTagsService.assignUserTagToUser(this.user.username, userTagAssignmentDto);
                    }
                }

                if (this.curafidaCardComponent.isCardCollapsed) this.curafidaCardComponent.isCardCollapsed = false;
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                this.log.error('Error in addNewTags', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async removeTag(tag: UserTag) {
        try {
            const index = this.user.tags.findIndex((tagItem) => tagItem.uuid === tag.uuid);
            this.user.tags.splice(index, 1);
            const userTagAssignmentDto = new UserTagAssignmentDto([tag.uuid]);
            if (this.user.roles.includes(UserRoles.PATIENT)) {
                await this.userTagsService.removeUserTagFromPatient(this.user.username, userTagAssignmentDto);
            } else {
                await this.userTagsService.removeUserTagFromUser(this.user.username, userTagAssignmentDto);
            }
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
        } catch (e) {
            this.log.error('Error in removeTag', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    toggleEdit() {
        this.isEditEnabled = true;
    }

    cancelEdit() {
        this.isEditEnabled = false;
    }
}
