import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserTagCategoryView } from '../../entities/user-tag/user-tag-category.entity';
import { UserTagsService } from '../../services/user-tags/user-tags.service';
import { User, UserRoles } from '../../../auth/entities/user';
import { StyleService } from '../../../common/services/style/style.service';
import { UserTag } from '../../entities/user-tag/user-tag.entity';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-user-tag-list-modal',
    templateUrl: './user-tag-list-modal.component.html',
    styleUrls: ['./user-tag-list-modal.component.scss'],
})
export class UserTagListModalComponent implements OnInit {
    title: string;
    tagCategories: UserTagCategoryView[];
    isComponentInitialised = false;
    isMobile: boolean;
    userRoles: UserRoles[];
    tags: UserTag[];
    user: User;
    selectedItems: UserTag[] = [];
    nullTag: UserTag = { uuid: null } as UserTag;
    enableFilterOptions: boolean = false;

    constructor(
        private modalController: ModalController,
        private params: NavParams,
        private userTagsService: UserTagsService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async ngOnInit() {
        try {
            this.userRoles = await this.params.get('userRoles');
            this.title = await this.params.get('title');
            this.tags = await this.params.get('tags');
            this.tagCategories = [];
            if (this.userRoles.includes(UserRoles.PATIENT)) {
                const userTagsCategories = await this.userTagsService.getUserTagCategories({
                    tenantOfUser: this.user?.groups[0],
                    userRole: UserRoles.PATIENT,
                });
                for (const category of userTagsCategories.items) {
                    this.tagCategories.push(category as UserTagCategoryView);
                }
            }
            if (this.userRoles.includes(UserRoles.CAREGIVER)) {
                const userTagsCategories = await this.userTagsService.getUserTagCategories({
                    tenantOfUser: this.user?.groups[0],
                    userRole: UserRoles.CAREGIVER,
                });
                for (const category of userTagsCategories.items) {
                    this.tagCategories.push(category as UserTagCategoryView);
                }
            }
            if (this.userRoles.includes(UserRoles.CATALOG_MANAGER)) {
                const userTagsCategories = await this.userTagsService.getUserTagCategories({
                    tenantOfUser: this.user?.groups[0],
                    userRole: UserRoles.CATALOG_MANAGER,
                });
                for (const category of userTagsCategories.items) {
                    this.tagCategories.push(category as UserTagCategoryView);
                }
            }
            if (this.userRoles.includes(UserRoles.USER_MANAGER)) {
                const userTagsCategories = await this.userTagsService.getUserTagCategories({
                    tenantOfUser: this.user?.groups[0],
                    userRole: UserRoles.USER_MANAGER,
                });
                for (const category of userTagsCategories.items) {
                    this.tagCategories.push(category as UserTagCategoryView);
                }
            }
            if (this.userRoles.includes(UserRoles.SUPERVISOR)) {
                const userTagsCategories = await this.userTagsService.getUserTagCategories({
                    tenantOfUser: this.user?.groups[0],
                    userRole: UserRoles.SUPERVISOR,
                });
                for (const category of userTagsCategories.items) {
                    this.tagCategories.push(category as UserTagCategoryView);
                }
            }
            if (this.tags) {
                for (const tagCategory of this.tagCategories) {
                    for (const tag of tagCategory.tags) {
                        tag.isSelected = !!this.tags.find((tagItem) => tagItem.uuid === tag.uuid);
                        if (tag.isSelected) this.selectedItems.push(tag);
                    }
                }
                if (this.tags.length === 1 && this.tags[0].uuid === null) {
                    this.selectedItems.push(this.nullTag);
                }
            }
        } finally {
            this.isComponentInitialised = true;
        }
    }

    async saveModal() {
        await this.modalController.dismiss(this.selectedItems).catch(console.error);
    }

    toggle(tag: UserTag): void {
        if (this.isSelected(tag)) {
            this.removeTagFromSelection(tag);
        } else {
            if (this.isSelected(this.nullTag)) {
                this.selectedItems = [];
            }
            this.selectedItems.push(tag);
        }
    }

    removeTagFromSelection(tag: UserTag) {
        this.selectedItems?.splice(
            this.selectedItems.findIndex((it) => tag.uuid === it.uuid),
            1,
        );
    }

    noTags() {
        if (this.isSelected(this.nullTag)) {
            this.removeTagFromSelection(this.nullTag);
        } else {
            this.removeFilter();
            this.selectedItems.push(this.nullTag);
        }
    }

    removeFilter() {
        for (const tagCategories of this.tagCategories) {
            for (const tag of tagCategories.tags) {
                tag.isSelected = false;
            }
        }
        this.selectedItems = [];
    }

    isSelected(tag: UserTag): boolean {
        return this.selectedItems?.findIndex((it) => it.uuid === tag.uuid) >= 0;
    }
}
