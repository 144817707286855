import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'curafida-toolbar-modal',
    templateUrl: './toolbar-modal.component.html',
    styleUrls: ['./toolbar-modal.component.scss'],
})
export class ToolbarModalComponent {
    @Input()
    title: string;

    @Input()
    hasActionButton = false;

    @Input()
    hasSecondaryButton = false;

    @Input()
    actionButtonLabel: string;

    @Input()
    secondaryButtonLabel: string;

    @Input()
    hasExternFunctionToDismiss: boolean;

    @Output()
    actionButtonClicked$ = new EventEmitter<null>();
    @Output()
    secondaryButtonClicked$ = new EventEmitter<null>();
    @Output()
    dismissActionButtonClicked$ = new EventEmitter<null>();

    constructor(public modalCtrl: ModalController) {}

    actionButtonClicked() {
        this.actionButtonClicked$.emit();
    }

    secondaryButtonClicked() {
        this.secondaryButtonClicked$.emit();
    }

    async dismiss() {
        if (this.hasExternFunctionToDismiss) {
            return this.dismissActionButtonClicked$.emit();
        }
        await this.modalCtrl.dismiss();
    }
}
