import { TableUpdateValue } from '../../../../../table/entities/table';
import { LoggingService } from '../../../../../logging/logging.service';
import { ModalController } from '@ionic/angular';
import { UsersService } from '../../../../../user/services/user';
import { ToastService } from '../../../../../common/services/toast-service/toast-service.service';
import { SupervisorService } from '../../../../../user/services/supervisor';
import { CurafidaAuthService } from '../../../../../auth/services';
import {
    SelectableUser,
    UserListModalComponent,
} from '../../../../../user/components/user-list-modal/user-list-modal.component';
import { Component, Input, OnInit } from '@angular/core';
import { UserRoles } from '../../../../../auth/entities/user';
import { PaginatedResponse } from '../../../../../common/entities/paginated-response';
import { ParticipationService } from '../participation.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'lib-participant-list-modal',
    templateUrl: '../../../../../user/components/user-list-modal/user-list-modal.component.html',
})
export class ParticipantListModalComponent extends UserListModalComponent implements OnInit {
    @Input()
    patientUsername: string;

    constructor(
        modalCtrl: ModalController,
        userService: UsersService,
        toastService: ToastService,
        supervisorService: SupervisorService,
        authService: CurafidaAuthService,
        loggingService: LoggingService,
        private readonly participationService: ParticipationService,
    ) {
        super(modalCtrl, userService, toastService, supervisorService, authService, loggingService);
    }

    async ngOnInit(): Promise<void> {
        return super.ngOnInit();
    }

    async initUserList(updateValue: TableUpdateValue): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.participationService
            .fetchSelectionOptions({
                role: this.roles[0] as UserRoles.CAREGIVER | UserRoles.SUPERVISOR,
                offset: updateValue.offset,
                limit: updateValue.limit,
                textSearchTerm: this.searchTerm,
                patientUsername: this.patientUsername,
                tagUuids: this.tags?.map((tag) => tag.uuid),
            })
            .pipe(
                tap((result) => {
                    const selectedUsernames = this.selectedUsers ? this.selectedUsers.map((it) => it.username) : [];
                    result.items = result.items.filter((it) => !selectedUsernames.includes(it.username));
                    result.count = result.items.length;
                }),
            )
            .subscribe(
                (it) => {
                    this.userListConfig.list = it as PaginatedResponse<SelectableUser[]>;
                    this.isLoadingSuccess = true;
                },
                (message) => {
                    this.log.error(message);
                    this.isLoadingSuccess = false;
                },
                () => (this.isLoading = false),
            );
    }
}
