import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tag } from '../../../entities/tag/tag.entity';
import { TagsService } from '../../../services/tags';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TagCategory, TagCategoryView } from '../../../entities/tag/tag-category.entity';
import { StyleService } from '../../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-tag-list-modal',
    templateUrl: './tag-list-modal.component.html',
    styleUrls: ['./tag-list-modal.component.scss'],
    /*
     * Making this components standalone breaks unit tests beyond only this component's unit test.
     * I have not been able to determine the reason behind it so I have decided to leave this as is.
     * My attempt to convert the component to standalone was as follows:
     * standalone: true,
     * imports: [CommonComponentsModule, TableModule],
     */
})
export class TagListModalComponent implements OnInit {
    title: string;
    @Input()
    exerciseTags: Tag[];
    tags: TagCategory[];
    @Input()
    exerciseType = ExerciseType.TRAINING;
    @Input()
    hideResetButton: boolean;
    isComponentInitialized = false;
    isMobile: boolean;
    selectedItems: Tag[] = [];

    constructor(
        private modalController: ModalController,
        private tagsService: TagsService,
        private styleService: StyleService,
    ) {
        this.isMobile = this.styleService.isMobile$;
    }

    async ngOnInit() {
        try {
            this.tags = (await this.tagsService.getTagCategories(this.exerciseType)) as TagCategoryView[];
            if (this.exerciseTags) {
                for (const tagCategory of this.tags) {
                    for (const tag of tagCategory.tags) {
                        tag.isSelected = !!this.exerciseTags.find((tagItem) => tagItem.uuid === tag.uuid);
                        if (tag.isSelected) this.selectedItems.push(tag);
                    }
                }
            }
        } finally {
            this.isComponentInitialized = true;
        }
    }

    async saveModal() {
        this.selectedItems = this.selectedItems.map((i) => {
            delete i.isSelected;
            return i;
        });
        await this.modalController.dismiss(this.selectedItems).catch(console.error);
    }

    removeFilter() {
        for (const tagCategories of this.tags) {
            for (const tag of tagCategories.tags) {
                tag.isSelected = false;
                this.selectedItems.splice(
                    this.selectedItems.findIndex((i) => i.uuid === tag.uuid),
                    1,
                );
            }
        }
    }

    toggle(tag: Tag): void {
        if (this.isSelected(tag)) {
            this.selectedItems.splice(
                this.selectedItems.findIndex((it) => tag.uuid === it.uuid),
                1,
            );
        } else {
            this.selectedItems.push(tag);
        }
    }

    isSelected(tag: Tag): boolean {
        return this.selectedItems.findIndex((it) => it.uuid === tag.uuid) >= 0;
    }
}
