import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Tag } from '../../../entities/tag/tag.entity';
import { StyleService } from '../../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExerciseType } from '../../../entities/exerciseSession';
import { map, take, tap } from 'rxjs/operators';
import { IconFilterItem, TaskOverviewFilterSetting } from '../../task/task-overview/task-overview.component';
import { UsersPreferencesService } from '../../../../user/services/user-preferences';
import { IconAdapterElement } from '../../../../table/components/table-adapter/icon-adapter.component';
import { ApiService } from '../../../../api';
import { HttpClient } from '@angular/common/http';
import { TagsService } from '../../../services/tags';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-task-filter-modal',
    templateUrl: './task-filter-modal.component.html',
    styleUrls: ['./task-filter-modal.component.scss'],
})
export class TaskFilterModalComponent {
    readonly ExerciseType = ExerciseType;

    taskOverviewFilterSetting: TaskOverviewFilterSetting;
    tagFilterSetting$: Observable<Tag[]>;
    iconFilterSetting$: Observable<IconFilterItem[]>;

    constructor(
        readonly styleService: StyleService,
        readonly usersPreferencesService: UsersPreferencesService,
        private readonly modalController: ModalController,
        private readonly httpClient: HttpClient,
        private readonly tagsService: TagsService,
    ) {}

    @Input()
    set filterSettingName(value: string) {
        this.tagFilterSetting$ = this.usersPreferencesService
            .settingByName<TaskOverviewFilterSetting>(value)
            .pipe(map((it) => it?.tags ?? []));
        this.iconFilterSetting$ = this.usersPreferencesService
            .settingByName<TaskOverviewFilterSetting>(value)
            .pipe(map((it) => it?.icons ?? []));
        this.usersPreferencesService
            .settingByName<TaskOverviewFilterSetting>(value)
            .pipe(
                take(1),
                map((it) => it ?? new TaskOverviewFilterSetting(value)),
                tap((it) => this.validateFilterSetting(it)),
            )
            .subscribe((it) => (this.taskOverviewFilterSetting = it));
    }

    set selectedTags(value: Tag[]) {
        this.taskOverviewFilterSetting.tags = value;
    }

    set selectedIcons(value: IconFilterItem[]) {
        this.taskOverviewFilterSetting.icons = value;
    }

    get filterCount() {
        return (
            (this.taskOverviewFilterSetting?.tags?.length ?? 0) + (this.taskOverviewFilterSetting?.icons?.length ?? 0)
        );
    }

    async accept() {
        await this.usersPreferencesService.updateFilterSettingItem(this.taskOverviewFilterSetting);
        await this.modalController.dismiss(this.taskOverviewFilterSetting).catch(console.error);
    }

    async clear() {
        this.selectedTags = [];
        this.selectedIcons = [];
        await this.usersPreferencesService.updateFilterSettingItem(this.taskOverviewFilterSetting);
    }

    private async validateFilterSetting(taskOverviewFilterSetting: TaskOverviewFilterSetting) {
        if (!taskOverviewFilterSetting.tags) {
            taskOverviewFilterSetting.tags = [];
        }
        if (!taskOverviewFilterSetting.icons) {
            taskOverviewFilterSetting.icons = [];
        }
        const syncJobs: Promise<boolean>[] = [];
        if (taskOverviewFilterSetting.tags.length > 0) {
            syncJobs.push(
                this.tagsService
                    .getTagCategories(ExerciseType.TASK)
                    .then((it) => it.flatMap((category) => category.tags))
                    .then((existingTags) =>
                        taskOverviewFilterSetting.tags.filter((filterTag) =>
                            existingTags.find((existing) => filterTag.uuid === existing.uuid),
                        ),
                    )
                    .then((syncedTags) => {
                        const isModified = taskOverviewFilterSetting.tags.length !== syncedTags.length;
                        if (isModified) {
                            taskOverviewFilterSetting.tags = syncedTags;
                        }
                        return isModified;
                    }),
            );
        }
        if (taskOverviewFilterSetting.icons.length > 0) {
            syncJobs.push(
                this.httpClient
                    .get<IconAdapterElement[]>(ApiService.url + 'tasks/mymedax-icons', ApiService.options)
                    .toPromise()
                    .then((existingIcons) =>
                        taskOverviewFilterSetting.icons.filter((filterIcon) =>
                            existingIcons.find(
                                (existing) => filterIcon.name === existing.name && filterIcon.color === existing.color,
                            ),
                        ),
                    )
                    .then((syncedIcons) => {
                        const isModified = taskOverviewFilterSetting.icons.length !== syncedIcons.length;
                        if (isModified) {
                            taskOverviewFilterSetting.icons = syncedIcons;
                        }
                        return isModified;
                    }),
            );
        }
        const modifications = await Promise.all(syncJobs);
        if (modifications.some((it) => it)) {
            await this.usersPreferencesService.updateFilterSettingItem(taskOverviewFilterSetting);
        }
    }
}
