export enum CsvPatientCreateStatus {
    /** Hinzugefügt */
    SUCCESSFUL_ADDED = 'SUCCESSFUL_ADDED',

    /** Ausgelassen, da ungültig oder unvollständig */
    SKIPPED_INVALID = 'SKIPPED_INVALID',

    /** Ignoriert, da schon vorhanden */
    SKIPPED_ALREADY_EXIST = 'SKIPPED_ALREADY_EXIST',
}

export class CsvPatientCreateResult {
    createStatus: CsvPatientCreateStatus;
    rawRecord: Record<string, string>;
    invalidFields: string[];
}

export class CsvImportProtocol {
    totalCsvRows: number;
    importedCsvRows: number;
    totalCsvValues: number;
    importedCsvValues: number;
    columnHeaders: string[];
    records: CsvPatientCreateResult[];
}
