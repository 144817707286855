import { Component, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Exercise, ExerciseDtoBuilder } from '../../entities/exercise';
import { TagListModalComponent } from '../modal/tag-list-modal/tag-list-modal.component';
import { Tag } from '../../entities/tag/tag.entity';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ExercisesService } from '../../services/exercises';
import { CurafidaCardComponent } from '../../../common/components/curafida-card/curafida-card.component';
import { ExerciseType } from '../../entities/exerciseSession';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { UserRoles } from '../../../auth/entities/user';
import { ICardActionButton } from '../../../common/entities/card-action-button.interface';

@Component({
    selector: 'lib-exercise-tag-view',
    templateUrl: './exercise-tag-view.component.html',
    styleUrls: ['./exercise-tag-view.component.scss'],
})
export class ExerciseTagViewComponent {
    @Input()
    exercise: Exercise;

    @Input()
    title = 'Tags';
    @Input()
    exerciseType = ExerciseType.TRAINING;
    @ViewChild('tagsCard') tagsCard: CurafidaCardComponent;
    @Input()
    hasRightToModified = false;
    UserRoles = UserRoles;
    actionButtons: ICardActionButton[] = [];
    protected readonly log: Logger;

    constructor(
        private toastService: ToastService,
        private exercisesService: ExercisesService,
        private modalController: ModalController,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.actionButtons.push({
            id: 'add-tag',
            isDisabled: false,
            isHidden: false,
            isIconButton: false,
            title: 'TAG.ADD_NEW',
        });
    }

    async addNewTags() {
        const modal = await this.modalController.create({
            component: TagListModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                title: this.exercise.title,
                exerciseTags: this.exercise.tags,
                exerciseType: this.exerciseType,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            try {
                this.exercise.tags = data;
                await this.exercisesService.updateExercise(
                    this.exercise.id,
                    new ExerciseDtoBuilder().copyExerciseDtoFromExercise(this.exercise).build(),
                );
                if (this.tagsCard.isCardCollapsed) this.tagsCard.isCardCollapsed = false;
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                this.log.error('Error in addNewTags', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async removeTag(tag: Tag) {
        try {
            const index = this.exercise.tags.findIndex((tagItem) => tagItem.uuid === tag.uuid);
            this.exercise.tags.splice(index, 1);
            await this.exercisesService.updateExercise(
                this.exercise.id,
                new ExerciseDtoBuilder().copyExerciseDtoFromExercise(this.exercise).build(),
            );
            await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
        } catch (e) {
            this.log.error('Error in removeTag', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }
}
