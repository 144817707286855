// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  border-bottom: 2px solid var(--ion-color-light-shade);
  vertical-align: bottom;
  font-size: 16px;
  font-weight: 700;
  padding-top: 1rem;
  padding-bottom: 0.75rem;
}

.lines {
  border-bottom: 1px solid var(--ion-color-light-shade);
  background: white !important;
}

.button-vertical-segment {
  --padding-start: 0;
  width: 100%;
  --background: none;
  white-space: pre-wrap;
  display: inline-block;
  vertical-align: middle;
  min-height: 30px;
}

.selected-div {
  border-left: 1px solid var(--ion-color-light-shade);
  border-bottom: 1px solid var(--ion-color-light-shade);
  border-top: 1px solid var(--ion-color-light-shade);
  border-right: 0 solid var(--ion-color-light-shade) !important;
}

.selected-button {
  color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./../../libs/user/components/user-tag/user-tag.component.scss"],"names":[],"mappings":"AAAA;EACI,qDAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,qDAAA;EACA,4BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,sBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mDAAA;EACA,qDAAA;EACA,kDAAA;EACA,6DAAA;AACJ;;AAEA;EACI,+BAAA;AACJ","sourcesContent":[".header {\n    border-bottom: 2px solid var(--ion-color-light-shade);\n    vertical-align: bottom;\n    font-size: 16px;\n    font-weight: 700;\n    padding-top: 1rem;\n    padding-bottom: 0.75rem;\n}\n\n.lines {\n    border-bottom: 1px solid var(--ion-color-light-shade);\n    background: white !important;\n}\n\n.button-vertical-segment {\n    --padding-start: 0;\n    width: 100%;\n    --background: none;\n    white-space: pre-wrap;\n    display: inline-block;\n    vertical-align: middle;\n    min-height: 30px;\n}\n\n.selected-div {\n    border-left: 1px solid var(--ion-color-light-shade);\n    border-bottom: 1px solid var(--ion-color-light-shade);\n    border-top: 1px solid var(--ion-color-light-shade);\n    border-right: 0 solid var(--ion-color-light-shade) !important;\n}\n\n.selected-button {\n    color: var(--ion-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
