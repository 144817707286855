import { UserTag } from './user-tag.entity';
import { BaseUserTag } from './base-user-tag';

export class UserTagCategory extends BaseUserTag {
    tags: UserTag[];
}

export class UserTagCategoryDto {
    label: string;
    userRole?: string;

    constructor(label: string, userRole: string) {
        this.label = label;
        this.userRole = userRole;
    }
}

export class UpdateUserTagCategoryDto {
    uuid: string;
    label: string;
}

export class UserTagCategoryView extends UserTagCategory {
    areSubLinesHidden: boolean;
    isDeleteIconDisabled = true;
    iconName = '';
}
